import { Button, TableBody } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Hits, useHits, useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import KanbanContext from '@/context/kanbanContext';
import { stableSort, getComparator } from '@/components/table/functions';
import { useBatchGetDealsTasksQuery } from '@/features/engagements/engagementsApi';
import { useBatchGetDealListPoliciesQuery } from '@/features/deals/dealsApi';

export const HitsTableBody = ({
	TableRowComponent, 
	EmptyRowComponent, 
	ErrorRowComponent,
	includeTasks = false,
	includePolicies = false,
	headCells, // optional
	order, // optional
	orderBy, // optional
	objectType,
	isError, 
	...props
}) => {
	const { items } = useHits(props);
	
	const dealIds = items.map(h => h.hubspotId ?? h.dealId ?? h.id);

	const tasksQuery = useBatchGetDealsTasksQuery(
		{ dealIds, objectType, nextTaskOnly: true }, 
		{ skip: dealIds.length === 0 || !includeTasks || !objectType }
	);
    
	const policiesQuery = useBatchGetDealListPoliciesQuery(
		{ dealIds },
		{ skip: dealIds.length === 0 || !includePolicies }
	);
	

	const updatedHits = useMemo(() => {
		const policyData = policiesQuery.data ?? [];
		let newItems = items.map(hit => {
			const earliestTask = (tasksQuery.data ?? {})[hit.hubspotId ?? hit.dealId ?? hit.id]?.properties;
			const next_task_date = earliestTask ? new Date(earliestTask.hs_timestamp) : null;
			return {
				...hit, 
				id: hit.hubspotId ?? hit.dealId ?? hit.id, docId: hit.id,
				earliestTask,
				next_task_date: hit.nextTaskDate ?? null,
				policies: (policyData[hit.dealId] ?? []),
				...(includePolicies && { policiesLoading: policiesQuery.isUninitialized || policiesQuery.isLoading || policiesQuery.isFetching })
			};
		});
		const sortCell = (headCells ?? []).find(i => orderBy && i.id === orderBy);
		if (sortCell?.frontEndSort) {
			newItems = stableSort(newItems, getComparator(order, orderBy, true));
		}
		return newItems;
	}, [items, tasksQuery.data, orderBy, order, headCells, policiesQuery]);

	
	return <TableBody>
		{isError ? <ErrorRowComponent /> : items.length > 0 ? updatedHits.map(hit => (
			<TableRowComponent key={hit.id} hit={hit} />
		)) : <EmptyRowComponent />}
	</TableBody>;
};


// const getStateWithoutPage = (state) => {
// 	const { page, ...rest } = state || {};
// 	return rest;
// };
  
// const getInMemoryCache = () => {
// 	let cachedHits = null;
// 	let cachedState = null;
// 	return {
// 		read({ state }) {
// 			return isEqual(cachedState, getStateWithoutPage(state))
// 				? cachedHits
// 				: null;
// 		},
// 		write({ state, hits }) {
// 			cachedState = getStateWithoutPage(state);
// 			cachedHits = hits;
// 		},
// 		invalidate() {
// 			cachedHits = null;
// 			cachedState = null;
// 		}
// 	};
// };

// export const cache = getInMemoryCache();

// const Refresh = () => {
// 	const { refresh, setUiState } = useInstantSearch();
  
// 	const resetAndRefresh = () => {
// 		setUiState((uiState) => ({
// 			...uiState,
// 			instant_search: {
// 				...uiState.instant_search,
// 				page: 0
// 			}
// 		}));
// 		cache.invalidate();
// 		refresh();
// 	};
  
// 	return <button onClick={resetAndRefresh}>Refresh</button>;
// };

export const HitsKanbanColumn = ({
	CardComponent,
	LoadingComponent,
	includeTasks,
	objectType,
	stage,
	...props
}) => {
	
	const { items, isLastPage, showMore } = useInfiniteHits({...props });
	const dealIds = useMemo(() => items.map(h => h.hubspotId ?? h.dealId ?? h.id), [items]);

	const tasksQuery = useBatchGetDealsTasksQuery(
		{ dealIds, objectType, nextTaskOnly: true }, 
		{ skip: dealIds.length === 0 || !includeTasks || !objectType }
	);

	const updatedHits = useMemo(() => {
		return items.map(hit => (
			{
				...hit, 
				id: hit.hubspotId ?? hit.dealId ?? hit.id, docId: hit.id,
				earliestTask: (tasksQuery.data ?? {})[hit.hubspotId ?? hit.dealId ?? hit.id]?.properties
			}
		));
	}, [items, tasksQuery.data]);

	const { refresh, setUiState } = useInstantSearch();
  
	const {
		dealStages, 
		updateDealStages,
		refreshStatus,
		updateRefreshStatus,
	} = useContext(KanbanContext);

	const resetAndRefresh = () => {
		updateRefreshStatus(stage.id);
		setUiState((uiState) => ({
			...uiState,
			instant_search: {
				...uiState.instant_search,
				page: 0
			}
		}));
		refresh();
		updateDealStages(stage.id, updatedHits);
	};

	const sentinelRef = useRef(null);

	useEffect(() => {
		const hitIds = updatedHits.map(h => h.id);
		const currentStageIds = (dealStages[stage.id] ?? []).map(h => h.id);
        
		// Check if there are any changes
		const hasChanges = hitIds.length !== currentStageIds.length || 
        hitIds.some(id => !currentStageIds.includes(id)) ||
        currentStageIds.some(id => !hitIds.includes(id));
    
		if (refreshStatus[stage.id]) {
			console.log('Triggering reset and refresh');
			setTimeout(async () => resetAndRefresh(), 5000);
		}
		if (hasChanges) {
			console.log('Updating stage.id', stage.id, updatedHits);
			updateDealStages(stage.id, updatedHits);
		}
	}, [updatedHits, dealStages, stage.id, refreshStatus[stage.id]]);
    
	useEffect(() => {
		if (sentinelRef.current !== null) {
			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !isLastPage) {//!isLastPage) {
						console.log(`SHOW MORE FOR ${stage.id} ${stage.label}`);
						showMore();
					}
				});
			});
    
			observer.observe(sentinelRef.current);
    
			return () => {
				observer.disconnect();
			};
		}
	}, [isLastPage, showMore]);
    
	return <>
		{updatedHits.length === 0 && refreshStatus[stage.id] ? <LoadingComponent /> : 
			updatedHits.map((hit, index) => (
				refreshStatus[stage.id] 
					?
					<LoadingComponent key={`loading-${index}`} /> 
					: 
					<CardComponent key={hit.id} hit={hit} index={index} />
			))}
		<div id={`sentinel-ref-${stage.id}`} ref={sentinelRef} {...{'aria-hidden' : true }} />
	</>;
};