import React, { useState, useEffect } from 'react';
import {
	Box,
	TextField,
	FormLabel,
	Container,
	Avatar,
	IconButton,
	CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ColorPicker from '@/components/ColourPicker';
import { useSelector } from 'react-redux';
import GooglePlacesAutocomplete from '@/components/selects/GoogleAddressSelect';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useUpdateBrokerMutation, useGetUserDetailsQuery } from '@/features/user/userApi';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { showToast } from '@/features/toast/toastSlice';

const BrokerageSettings = () => {
	const dispatch = useDispatch();
	const [imageFile, setImageFile] = useState(null);
	const email = useSelector((state) => state.msalAccount.account?.username);
	const { data: userDetails, isLoading: isUserLoading } = useGetUserDetailsQuery(
		{ email },
		{ skip: !email }
	);
	const [updateBroker] = useUpdateBrokerMutation();
	const [logoFile, setLogoFile] = useState(null);
	const [logoPreviewUrl, setLogoPreviewUrl] = useState();
	const [savingField, setSavingField] = useState(null);

	// Colors State (unchanged)
	const [colors, setColors] = useState({
		primary: '#505afc',
		secondary: '#DADCFF',
		tertiary: '#333333',
	});

	const formik = useFormik({
		initialValues: {
			claimsEmail: '',
			website: '',
			about: '',
			streetAddress: '',
			suburb: '',
			city: '',
			postcode: '',
			termsOfEngagement: '',
			privacyStatement: '',
			fullLogo: '',
		},
	});

	const saveBrokerDetails = async (values) => {
		try {
			const combinedAddress = [
				values.streetAddress.trim(),
				values.suburb.trim(),
				values.city.trim(),
				values.postcode.trim(),
			]
				.filter(Boolean)
				.join(', ');

			await updateBroker({
				docId: userDetails?.brokerId,
				properties: {
					claimsEmail: (values.claimsEmail ?? '').toLowerCase(),
					website: values.website,
					about: values.about,
					address: combinedAddress,
					termsOfEngagement: values.termsOfEngagement,
					privacyStatement: values.privacyStatement,
				},
			}).unwrap();

			dispatch(showToast({ message: 'Changes saved successfully!' }));
		} catch (error) {
			console.error('Error updating broker:', error);
			dispatch(showToast({ message: 'Error saving changes' }));
		}
	};

	useEffect(() => {
		if (userDetails) {
			formik.setValues({
				claimsEmail: userDetails.claimsEmail ?? '',
				website: userDetails.website ?? '',
				about: userDetails.about ?? '',
				termsOfEngagement: userDetails.termsOfEngagement ?? '',
				privacyStatement: userDetails.privacyStatement ?? '',
				...parseAddress(userDetails.businessAddress),
				fullLogo: userDetails.fullLogo ?? '',
			});

			setColors({
				primary: userDetails.colours?.primary || '#505afc',
				secondary: userDetails.colours?.secondary || '#DADCFF',
				tertiary: userDetails.colours?.tertiary || '#333333',
			});

			setLogoPreviewUrl(userDetails.fullLogo || '');
		}
	}, [userDetails]);

	const parseAddress = (address) => {
		if (!address) return {};
		const [streetAddress, suburb, city, postcode] = address.split(',').map(part => part.trim());
		return { streetAddress, suburb, city, postcode };
	};

	const handleLogoUpload = async (e) => {
		// Keep original logo upload implementation unchanged
		const file = e.target.files?.[0];
		if (!file) return;

		try {
			setImageFile(file);
			const localPreview = URL.createObjectURL(file);
			setLogoPreviewUrl(localPreview);

			const storage = getStorage();
			const storageRef = ref(storage, `brokerData/${userDetails.brokerId}/${file.name}`);
			await uploadBytes(storageRef, file);

			const downloadUrl = await getDownloadURL(storageRef);
			formik.setFieldValue('fullLogo', downloadUrl);
			setLogoPreviewUrl(downloadUrl);

			await updateBroker({
				docId: userDetails.brokerId,
				properties: { fullLogo: downloadUrl },
			}).unwrap();

			dispatch(showToast({ message: 'Logo uploaded successfully!' }));
		} catch (err) {
			console.error('Error uploading logo:', err);
			dispatch(showToast({ message: 'Error uploading logo' }));
		}
	};

	const handleColorChange = async (field, color) => {
		setColors((prev) => ({ ...prev, [field]: color }));
		setSavingField(field);

		try {
			await updateBroker({
				docId: userDetails?.brokerId,
				properties: {
					colours: {
						...colors,
						[field]: color, // Ensure the new color is included in the update
					},
				},
			}).unwrap();

			dispatch(showToast({ message: 'Color updated successfully!' }));
		} catch (error) {
			console.error('Error updating color:', error);
			dispatch(showToast({ message: 'Error updating color' }));
		} finally {
			setSavingField(null);
		}
	};


	const handlePlaceSelect = async (place) => {
		if (!place?.address_components) return;

		const addressComponents = place.address_components;
		const streetNumber = addressComponents.find((comp) => comp.types.includes('street_number'))?.long_name || '';
		const route = addressComponents.find((comp) => comp.types.includes('route'))?.long_name || '';
		const combinedStreet = [streetNumber, route].filter(Boolean).join(' ');
		const suburb = addressComponents.find((comp) => comp.types.includes('sublocality_level_1'))?.long_name ||
      addressComponents.find((comp) => comp.types.includes('sublocality'))?.long_name || '';
		const city = addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || '';
		const postcode = addressComponents.find((comp) => comp.types.includes('postal_code'))?.long_name || '';

		const updatedValues = {
			...formik.values,
			streetAddress: combinedStreet.trim(),
			suburb,
			city,
			postcode,
		};

		formik.setValues(updatedValues);
		setSavingField('address');
		try {
			await saveBrokerDetails(updatedValues);
		} finally {
			setSavingField(null);
		}
	};

	const handleFieldBlur = async (e) => {
		formik.handleBlur(e);
		setSavingField(e.target.name);
		try {
			await saveBrokerDetails(formik.values);
		} finally {
			setSavingField(null);
		}
	};

	return (
		<Container>
			<form>
				<Grid>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Box sx={{ position: 'relative', width: 150, height: 150}}>
							<Avatar
								src={logoPreviewUrl || ''}
								alt="Broker Logo"
								sx={{ width: '100%', height: '100%', bgcolor: '#999999' }}
							>
								{!logoPreviewUrl && 'Logo'}
							</Avatar>
							<Box sx={{ 
								position: 'absolute', 
								top: 0, 
								left: 0, 
								width: '100%', 
								height: '100%',
								borderRadius: '50%',
								bgcolor: 'rgba(0, 0, 0, 0.4)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								opacity: 0,
								transition: 'opacity 0.3s ease',
								cursor: 'pointer',
								'&:hover': { opacity: 1 },
							}}>
								<IconButton component="label" sx={{ color: 'white' }}>
									<CloudUploadIcon fontSize="large" />
									<input type="file" hidden accept="image/*" onChange={handleLogoUpload} />
								</IconButton>
							</Box>
						</Box>
					</Box>
				</Grid>

				<Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
					<Grid container spacing={2} justifyContent="center" sx={{ maxWidth: 800 }}>
						{['primary', 'secondary', 'tertiary'].map((color) => (
							<Grid size={4} key={color}>
								<ColorPicker
									label={`${color.charAt(0).toUpperCase()}${color.slice(1)} Color`}
									initialColor={colors[color]}
									onChange={(newColor) => handleColorChange(color, newColor)}
									key={color + colors[color]}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
				<Grid container spacing={2}>
					<Grid size={12}>
						<FormLabel>Search Address</FormLabel>
						<GooglePlacesAutocomplete onSelect={handlePlaceSelect} />
					</Grid>

					{['streetAddress', 'suburb', 'city', 'postcode'].map((field) => (
						<Grid size={6} key={field}>
							<FormLabel>
								{field
									.replace(/([a-z])([A-Z])/g, '$1 $2')
									.replace(/(^\w|\s\w)/g, (c) => c.toUpperCase())}
							</FormLabel>
							<TextField
								fullWidth
								name={field}
								value={formik.values[field]}
								onChange={formik.handleChange}
								onBlur={handleFieldBlur}
								InputProps={{
									endAdornment: savingField === field && <CircularProgress size={20} />,
								}}
							/>
						</Grid>
					))}

					{['claimsEmail', 'website', 'about', 'termsOfEngagement', 'privacyStatement'].map((field) => (
						<Grid size={field === 'about' ? 12 : 6} key={field}>
							<FormLabel>
								{field
									.replace(/([a-z])([A-Z])/g, '$1 $2')
									.replace(/(^\w|\s\w)/g, (c) => c.toUpperCase())}
							</FormLabel>
							<TextField
								fullWidth
								multiline={field === 'about'}
								rows={field === 'about' ? 4 : 1}
								name={field}
								value={formik.values[field]}
								onChange={formik.handleChange}
								onBlur={handleFieldBlur}
								InputProps={{
									endAdornment: savingField === field && <CircularProgress size={20} />,
								}}
							/>
						</Grid>
					))}
				</Grid>
			</form>
		</Container>
	);
};

export default BrokerageSettings;