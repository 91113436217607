import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	Box,
	TextField,
	Avatar,
	IconButton,
	FormLabel,
	FormHelperText,
	Container,
	Stack,
	Typography,
	CircularProgress,
	InputAdornment
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';
import { useGetUserDetailsQuery, useUpdateUserProfileMutation } from '@/features/user/userApi';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { BrokerSelectProfile } from '@/components/selects/BrokerSelectProfile';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { debounce } from 'lodash';

export const UserSettings = () => {
	const dispatch = useDispatch();
	const [imageFile, setImageFile] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(null);
	const [isImageUploading, setIsImageUploading] = useState(false);
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { data: userDetails } = useGetUserDetailsQuery({ email }, { skip: !email });
	const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();

	const formik = useFormik({
		initialValues: {
			email: '',
			phoneNumber: '',
			linkedIn: '',
			imageUrl: '',
			aboutUser: '',
			fspNumber: '',
			brokerSupportId: '',
			claimsAdviserId: '',
			complianceAdviserId: '',
			disclosureUrl: '',
		},
	});

	const handleAutoSave = useMemo(
		() =>
			debounce(async (values) => {
				try {
					await updateUserProfile({
						email,
						properties: {
							...values,
						},
					}).unwrap();
					dispatch(showToast({ message: 'Changes saved automatically' }));
				} catch (err) {
					console.error('Auto-save failed:', err);
					dispatch(showToast({ message: 'Error saving changes' }));
				}
			}, 500),
		[email, updateUserProfile, dispatch]
	);

	useEffect(() => {
		return () => handleAutoSave.cancel();
	}, [handleAutoSave]);

	useEffect(() => {
		if (userDetails) {
			formik.setValues({
				email: userDetails.email ?? '',
				phoneNumber: userDetails.phoneNumber ?? '',
				linkedIn: userDetails.linkedIn ?? '',
				disclosureUrl: userDetails.disclosureUrl ?? '',
				aboutUser: userDetails.aboutUser ?? '',
				fspNumber: userDetails.fspNumber ?? '',
				imageUrl: userDetails.imageUrl ?? '',
				brokerSupportId: userDetails.brokerSupportId ?? '',
				claimsAdviserId: userDetails.claimsAdviserId ?? '',
				complianceAdviserId: userDetails.complianceAdviserId ?? '',
			});

			if (userDetails.imageUrl) {
				setPreviewUrl(userDetails.imageUrl);
			}
		}
	}, [userDetails]);

	const handleImageUpload = async (e) => {
		const file = e.target.files?.[0];
		if (!file) return;

		try {
			setIsImageUploading(true);
			setImageFile(file);
			const localPreview = URL.createObjectURL(file);
			setPreviewUrl(localPreview);

			const storage = getStorage();
			const storageRef = ref(storage, `userData/${userDetails.userId}/${file.name}`);
			await uploadBytes(storageRef, file);

			const downloadUrl = await getDownloadURL(storageRef);
			formik.setFieldValue('imageUrl', downloadUrl);
			setPreviewUrl(downloadUrl);
			handleAutoSave({ ...formik.values, imageUrl: downloadUrl });

			dispatch(showToast({ message: 'Image uploaded successfully!' }));
		} catch (err) {
			console.error('Error uploading image:', err);
			dispatch(showToast({ message: 'Error uploading image' }));
		} finally {
			setIsImageUploading(false);
		}
	};

	const handleFieldBlur = (e) => {
		formik.handleBlur(e);
		handleAutoSave(formik.values);
	};

	const handleSelectChange = (fieldName) => (value) => {
		formik.setFieldValue(fieldName, value);
		handleAutoSave({ ...formik.values, [fieldName]: value });
	};

	return (
		<Container>
			<Stack spacing={2}>
				<Stack spacing={2} alignItems={'center'} justifyContent={'center'} pb={3}>
					<Box sx={{ position: 'relative', width: 150, height: 150 }}>
						<Avatar
							src={previewUrl || ''}
							alt='Profile Picture'
							sx={{ width: '100%', height: '100%', backgroundColor: '#999999' }}
						>
							{!previewUrl && 'Upload'}
						</Avatar>
						{isImageUploading ? (
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									borderRadius: '50%',
									backgroundColor: 'rgba(0, 0, 0, 0.4)',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<CircularProgress sx={{ color: 'white' }} />
							</Box>
						) : (
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									borderRadius: '50%',
									backgroundColor: 'rgba(0, 0, 0, 0.4)',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									opacity: 0,
									transition: 'opacity 0.3s ease',
									cursor: 'pointer',
									'&:hover': { opacity: 1 },
								}}
							>
								<IconButton component='label' sx={{ color: 'white' }}>
									<CloudUploadIcon fontSize='large' />
									<input
										type='file'
										hidden
										accept='image/*'
										onChange={handleImageUpload}
									/>
								</IconButton>
							</Box>
						)}
					</Box>
					<Typography variant='broker_header'>{email}</Typography>
				</Stack>

				<Grid container spacing={3}>
					<Grid size={6} md={6}>
						<FormLabel>FSP Number</FormLabel>
						<TextField
							fullWidth
							variant='outlined'
							name='fspNumber'
							value={formik.values.fspNumber}
							onChange={formik.handleChange}
							onBlur={handleFieldBlur}
							error={formik.touched.fspNumber && Boolean(formik.errors.fspNumber)}
							helperText={formik.touched.fspNumber && formik.errors.fspNumber}
							InputProps={{
								endAdornment: isLoading && (
									<InputAdornment position="end">
										<CircularProgress size={20} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid size={6} md={6}>
						<FormLabel>Phone Number</FormLabel>
						<TextField
							fullWidth
							variant='outlined'
							name='phoneNumber'
							value={formik.values.phoneNumber}
							onChange={formik.handleChange}
							onBlur={handleFieldBlur}
							error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
							helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
							InputProps={{
								endAdornment: isLoading && (
									<InputAdornment position="end">
										<CircularProgress size={20} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid size={6} md={6}>
						<FormLabel>LinkedIn Profile URL</FormLabel>
						<TextField
							fullWidth
							variant='outlined'
							name='linkedIn'
							value={formik.values.linkedIn}
							onChange={formik.handleChange}
							onBlur={handleFieldBlur}
							error={formik.touched.linkedIn && Boolean(formik.errors.linkedIn)}
							helperText={formik.touched.linkedIn && formik.errors.linkedIn}
							InputProps={{
								endAdornment: isLoading && (
									<InputAdornment position="end">
										<CircularProgress size={20} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid size={6} md={6}>
						<FormLabel>Disclosure Statement</FormLabel>
						<TextField
							fullWidth
							variant='outlined'
							name='disclosureUrl'
							value={formik.values.disclosureUrl}
							onChange={formik.handleChange}
							onBlur={handleFieldBlur}
							error={formik.touched.disclosureUrl && Boolean(formik.errors.disclosureUrl)}
							helperText={formik.touched.disclosureUrl && formik.errors.disclosureUrl}
							InputProps={{
								endAdornment: isLoading && (
									<InputAdornment position="end">
										<CircularProgress size={20} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid size={12}>
						<FormLabel>About My Services</FormLabel>
						<TextField
							fullWidth
							variant='outlined'
							name='aboutUser'
							multiline
							rows={4}
							value={formik.values.aboutUser}
							onChange={formik.handleChange}
							onBlur={handleFieldBlur}
							error={formik.touched.aboutUser && Boolean(formik.errors.aboutUser)}
							helperText={formik.touched.aboutUser && formik.errors.aboutUser}
							InputProps={{
								endAdornment: isLoading && (
									<InputAdornment position="end">
										<CircularProgress size={20} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>

				<Stack>
					<FormLabel>My Service Team</FormLabel>
					<FormHelperText sx={{ marginLeft: 0 }}>
              The following team members will appear on your generated Statement of Advice
					</FormHelperText>
				</Stack>
		
				<Grid container spacing={2} sx={{ mb: 5 }}>
					{['brokerSupportId', 'claimsAdviserId', 'complianceAdviserId'].map((field, index) => (
						<Grid size={4} key={field}>
							<FormLabel>
								{field === 'brokerSupportId' && 'Broker Support'}
								{field === 'claimsAdviserId' && 'Claims Adviser'}
								{field === 'complianceAdviserId' && 'Compliance Adviser'}
							</FormLabel>
							<Box sx={{ position: 'relative' }}>
								<OwnerSelect
									initialId={formik.values[field]}
									onChange={handleSelectChange(field)}
									disabled={isLoading}
								/>
								{isLoading && (
									<CircularProgress
										size={24}
										sx={{
											position: 'absolute',
											top: '50%',
											right: 8,
											marginTop: '-12px',
										}}
									/>
								)}
							</Box>
						</Grid>
					))}
				</Grid>
	
			</Stack>
		</Container>
	);
};