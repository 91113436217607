// import React, { useCallback } from 'react';
// import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
// import InputBase from '@mui/material/InputBase';
// import Box from '@mui/material/Box';
// import { styled } from '@mui/material/styles';
// import { useGridApiContext } from '@mui/x-data-grid';

// const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
// 	height: '100%',
// 	[`& .${autocompleteClasses.inputRoot}`]: {
// 		...theme.typography.body2,
// 		padding: '1px 0',
// 		height: '100%',
// 		'& input': {
// 			padding: '0 16px',
// 			height: '100%',
// 		},
// 	},
// }));

// function EditCountry({ id, value, field }) {
// 	const apiRef = useGridApiContext();

// 	const handleChange = useCallback(
// 		async (event, newValue) => {
// 			await apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
// 			apiRef.current.stopCellEditMode({ id, field });
// 		},
// 		[apiRef, field, id]
// 	);

// 	return (
// 		<StyledAutocomplete
// 			value={value}
// 			onChange={handleChange}
// 			options={COUNTRY_ISO_OPTIONS}
// 			getOptionLabel={(option) => option.label}
// 			autoHighlight
// 			fullWidth
// 			open
// 			disableClearable
// 			renderOption={(optionProps, option) => (
// 				<Box
// 					component="li"
// 					sx={{
// 						'& > img': {
// 							mr: 1.5,
// 							flexShrink: 0,
// 						},
// 					}}
// 					{...optionProps}
// 					key={option.code}
// 				>
// 					<img
// 						loading="lazy"
// 						width="20"
// 						src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
// 						srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
// 						alt=""
// 					/>
// 					{option.label}
// 				</Box>
// 			)}
// 			renderInput={(params) => (
// 				<InputBase
// 					autoFocus
// 					fullWidth
// 					id={params.id}
// 					inputProps={{
// 						...params.inputProps,
// 						autoComplete: 'new-password', // Disable autocomplete and autofill
// 					}}
// 					{...params.InputProps}
// 				/>
// 			)}
// 		/>
// 	);
// }

// export function renderEditCountry(params) {
// 	return <EditCountry {...params} />;
// }
import React from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

function InsurerSelect({ id, value, field, row, colDef }) {
	const apiRef = useGridApiContext();

	const options = Array.isArray(colDef?.valueOptions)
		? colDef.valueOptions
		: colDef?.valueOptions
		? colDef?.valueOptions(row) ?? []
		: [];

	const val = options?.find((o) => o?.code === value)?.code ?? '';

	const handleChange = async (event) => {
		const newValue = event.target.value;

		// Update the cell value in the DataGrid
		await apiRef.current.setEditCellValue({ id, field, value: newValue });

		// Exit edit mode
		apiRef.current.stopCellEditMode({ id, field });
	};

	const handleClose = (event, reason) => {
		if (reason === 'backdropClick') {
			apiRef.current.stopCellEditMode({ id, field });
		}
	};

	return (
		<Select
			value={val}
			onChange={handleChange}
			MenuProps={{ onClose: handleClose }}
			sx={{
				height: '100%',
				'& .MuiSelect-select': {
					display: 'flex',
					alignItems: 'center',
					pl: 1,
				},
			}}
			autoFocus
			fullWidth
			open
		>
			{options.map((option) => {
				return (
					<MenuItem
						key={option?.code ?? -1}
						value={option?.code ?? -1}
						disabled={option?.disabled}
					>
						<ListItemText primary={option?.name ?? ''} />
						{/* <ListItemIcon sx={{ minWidth: 36 }}>{code}</ListItemIcon>
						<ListItemText
							primary={tooltip}
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						/> */}
					</MenuItem>
				);
			})}
		</Select>
	);
}

export function renderInsurerSelect(params) {
	// console.log('renderInsurerSelect PARAMS', params);
	return <InsurerSelect {...params} />;
}
