/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Table,
	TableCell,
	TableContainer,
	TableFooter,
	TableRow,
	Typography,
	Paper,
	Divider,
	Box,
	Stack,
	IconButton,
	Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import { InstantSearch, Configure, useInstantSearch } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { DealRow } from './DealRow';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { SearchFolioHead } from '@/components/table/FolioTableHead';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { rowLimits } from '@/components/table/data';
import {
	changeRowsPerPage,
	setOrder,
	setOrderBy,
	requestSort,
	onOrderChange,
	setSelected,
} from '@/features/table/dealsTableSlice';
import { KanbanToggle } from '@/components/buttons/KanbanToggle';
import { features } from '@/utils/features';
import { TicketRow } from '@/components/tables/ClaimsTable/TicketRow';
import { RefinementListSelect } from '@/components/selects/RefinementListSelect';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { ClientTypeRefinementListSelect } from '@/components/selects/ClientTypeRefinementListSelect';
import FilterMenu from '@/components/table/FilterMenu';
import { RenewalsRow } from './RenewalsRow';
import { IS_DEV } from '@/utils/environment';
import { DataGrid } from '@mui/x-data-grid';
import { HitsDataGrid } from '@/components/table/ClaimDataGrid';
import { DealTableRefresh } from '@/components/table/DealTableRefresh';
import { DEAL_STATUS_TYPES } from '@/utils/constants';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ExportButton } from '@/components/buttons/ExportButton';
import { useLocation } from 'react-router-dom';

export const dealHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	//insuredName
	{ id: 'dealName', numeric: false, label: 'Deal name', align: 'left' },
	{ id: 'clientName', numeric: false, label: 'Client name', align: 'left' },
	// { id: 'contactName', numeric: false, label: 'Contact', align: 'left' },
	{ id: 'ownerId', numeric: false, label: 'Assignee', align: 'left' },
	{ id: 'createDate', numeric: false, date: true, label: 'Created date', align: 'right' },
	// {
	// 	id: 'hs_lastmodifieddate',
	// 	numeric: false,
	// 	date: true,
	// 	label: 'Last Updated',
	// 	align: 'right',
	// },
	{ id: 'renewalDate', numeric: false, date: true, label: 'Renewal date', align: 'right' },
	{ id: 'dealStage', numeric: false, label: 'Stage', align: 'left' },
	// { id: 'hs_priority', numeric: false, label: 'Priority', align: 'center' },
	// { id: 'id', numeric: false, label: '', sortable: false, align: 'center' },
];

export const renewalHeadCells = [
	{ id: 'id', numeric: false, label: null, sortable: false },
	{ id: 'dealName', numeric: false, label: 'Deal name', align: 'left' },
	{ id: 'clientName', numeric: false, label: 'Client name', align: 'left' },
	{ id: 'policies', numeric: false, label: 'Associated policies', align: 'left' },
	// { id: 'contactName', numeric: false, label: 'Contact', align: 'left' },

	{ id: 'ownerId', numeric: false, label: 'Assignee', align: 'left' },
	{ id: 'pipeline', numeric: false, label: 'Pipeline', align: 'left' },
	{ id: 'renewalDate', numeric: false, date: true, label: 'Renewal date', align: 'right' },
	{ id: 'status', numeric: false, label: 'Status/Stage', align: 'left' },
];

export const ticketHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	//insuredName
	{ id: 'dealName', numeric: false, label: 'Claim name', align: 'left', width: '15%' },
	{ id: 'clientName', numeric: false, label: 'Client name', align: 'left', width: '30%' },
	{ id: 'claimType', numeric: false, label: 'Claim Type', align: 'left', width: '10%' },
	{ id: 'claimNumber', numeric: false, label: 'Claim Number', align: 'left', width: '10%' },
	{ id: 'insurer', numeric: false, label: 'Insurer', align: 'left', width: '20%' },
	{ id: 'ownerId', numeric: false, label: 'Assignee', align: 'left', width: '10%' },
	{ id: 'dateOfLoss', numeric: false, label: 'Date of loss', align: 'left', width: '10%' },
	{
		id: 'nextTaskDate',
		numeric: false,
		label: 'Next Task Date',
		align: 'left',
		date: true,
		// frontEndSort: true,
		width: '10%',
		// sortable: false,
	},
	{
		id: 'createDate',
		numeric: false,
		date: true,
		label: 'Created date',
		align: 'left',
		width: '10%',
	},
	{ id: 'dealStage', numeric: false, label: 'Stage', align: 'left', width: '10%' },
	// {
	// 	id: 'hs_lastmodifieddate',
	// 	numeric: false,
	// 	date: true,
	// 	label: 'Last Updated',
	// 	align: 'right',
	// },
];

export default function DealTable({ pipeline, tab }) {
	const dispatch = useDispatch();

	useEffect(() => console.log('PIPELINE ID', pipeline), [pipeline]);

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects' });
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const isClaims = pipeline == process.env.REACT_APP_PIPELINE_CLAIMS;
	const isNewBusiness = pipeline == process.env.REACT_APP_PIPELINE_NEW_BUSINESS;

	const headCells = useMemo(() => {
		const cells = !pipeline
			? renewalHeadCells.slice()
			: isClaims
				? ticketHeadCells.slice()
				: dealHeadCells.slice();
		if (showProspects && isNewBusiness) {
			cells.splice(2, 0, {
				id: 'isProspect',
				numeric: false,
				label: 'Client Type',
				align: 'left',
				sortable: false,
			});
		}
		return cells;
	}, [isClaims, showProspects, pipeline]);

	const [prospectFilter, setProspectFilter] = useState('');
	const { pathname: slug } = useLocation();
	const { groupView, hubspotId: groupViewHubspotId } = useSelector((state) => state.userPrefs);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	
	const { rowsPerPage, selected, orders } = useSelector((state) => state.dealTable);
	const filters = useMemo(() => orders[slug] ?? { order: 'desc', orderBy: 'createdate' }, [orders, slug]);
	const { order, orderBy } = filters;
	
	
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const userDetailsHubspotId = userDetails?.hubspotId;
	const hubspotId = groupViewHubspotId ?? userDetailsHubspotId ?? 0;

	const brokerGroupId = userDetails?.brokerId;
	const brokerGroupIds = userDetails?.brokerGroupArray ?? [];

	useEffect(() => {
		const renewalsPipeline = process.env.REACT_APP_PIPELINE_RENEWALS;
	  
		dispatch(
		  setOrderBy({
				slug,
				orderBy: !pipeline || pipeline == renewalsPipeline ? 'renewalDate' : orderBy,
		  })
		);
		dispatch(
		  setOrder({
				slug,
				order: !pipeline || pipeline == renewalsPipeline ? 'asc' : order,
		  })
		);
	  }, [pipeline, slug]);  
	  
	useEffect(() => {
		dispatch(onOrderChange());
	}, [orderBy, order]);

	const sortItems = headCells.reduce((acc, cell, index) => {
		if (index === 0) {
			acc.push({ value: 'deals', label: cell.label });
		}
		if (cell.sortable !== false && !cell.frontEndSort) {
			acc.push({ value: `deals/sort/${cell.id}:asc`, label: `${cell.label} (asc)` });
			acc.push({ value: `deals/sort/${cell.id}:desc`, label: `${cell.label} (desc)` });
		}
		return acc;
	}, []);

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		// skip: !pipeline,
	});

	const { active, inactive } = useMemo(() => {
		const pipelineStages =
			pipelineQuery.data?.pipelines?.stages ??
			(pipelineQuery.data?.pipelines?.results ?? [])
				.filter((p) => p.id !== process.env.REACT_APP_PIPELINE_CLAIMS)
				.flatMap((r) => r?.stages) ??
			[];
		return {
			active: pipelineStages
				.filter((stage) => stage.metadata?.isClosed != 'true')
				.map((s) => s.id),
			inactive: pipelineStages
				.filter(
					(stage) =>
						stage.metadata?.probability?.toString() == '1.0' ||
						(IS_DEV &&
							stage.metadata?.isClosed == 'true' &&
							stage.metadata?.probability != '0.0') ||
						(isClaims && stage.metadata?.isClosed == 'true')
				)
				.map((s) => s.id),
		};
	}, [pipelineQuery.data]);

	const handleRequestSort = (event, property) => {
		dispatch(requestSort({ property, slug }));
	};
	  

	const handleChangeRowsPerPage = (event) => {
		// console.log('event: ', event);
		dispatch(changeRowsPerPage(event.target.value));
	};

	const filterSchema = useMemo(() => {
		console.log('ACTIVE STAGES', active, 'INACTIVE STAGES', inactive);
		const stageIds = (tab == 'active' ? active : inactive).join(',');
		const pipelines = [
			process.env.REACT_APP_PIPELINE_NEW_BUSINESS,
			process.env.REACT_APP_PIPELINE_ENDORSEMENTS,
		];
		const onlyShowInProgress = `status:!=${DEAL_STATUS_TYPES.endorsementCreated} && status:!=${DEAL_STATUS_TYPES.renewalCreated} && status:!=${DEAL_STATUS_TYPES.archived}`;
		// const onlyShowInProgress = `status:=${DEAL_STATUS_TYPES.notStarted}`;
		const renewalsFilter = `((pipeline:=${
			process.env.REACT_APP_PIPELINE_RENEWALS
		}) || (pipeline: [${pipelines.join(',')}] && dealStage: [${inactive.join(
			','
		)}])) && ${onlyShowInProgress}`;
		const ownerFilter = groupView
			? `brokerGroupId: [${brokerGroupIds.join(',')}]`
			: `ownerId := ${hubspotId}`;
		const schema = {
			filters: !pipeline
				? `${renewalsFilter} && ${ownerFilter}`
				: `dealStage: [${stageIds}] && ${ownerFilter}${
					prospectFilter.length > 0 ? ` && ${prospectFilter}` : ''
				  }`,
		};
		return schema;
	}, [tab, pipeline, active, inactive, hubspotId, brokerGroupIds, groupView, prospectFilter]);


	return (
		<InstantSearch indexName='deals' searchClient={dealClient}>
			<Configure {...filterSchema} />
			<DealTableRefresh />
			<Grid container sx={{ alignItems: 'flex-start' }} p={1} px={2} spacing={1}>
				<Grid size={{ xs: 12, sm: 5 }}>
					<InstantSearchFieldAdaptor />
				</Grid>
				<Grid size={{ xs: 0, sm: 'grow' }}></Grid>
				{(isClaims || !pipeline) && (
					<Grid
						size={{ xs: 12, sm: 6 }}
						container
						justifyContent={'flex-end'}
						display={'flex'}
					>
						{/* <Grid>
							<Box pr={1}>
								<RefinementListSelect
									attribute='dealStage'
									title='Stage'
									multiple={true}
									pipeline={pipeline}
								/>
							</Box>
						</Grid>
						<Grid>
							<Box pr={1}>
								<RefinementListSelect
									attribute='claimType'
									title='Claim Type'
									multiple={true}
								/>
							</Box>
						</Grid>
						<Grid>
							<Box pr={1}>
								<RefinementListSelect
									attribute='insurer'
									title='Insurer'
									multiple={true}
								/>
							</Box>
						</Grid> */}
						<Grid>
							<FilterMenu
								title={
									!pipeline
										? 'Renewals'
										: pipelineQuery?.data?.pipelines?.label ?? ''
								}
								filters={
									isClaims
										? [
											{
												attribute: 'insurer',
												multiple: true,
												title: 'Insurer',
											},
											{
												attribute: 'claimType',
												multiple: true,
												title: 'Claim Type',
											},
											{
												attribute: 'dealStage',
												multiple: true,
												title: 'Stage',
												pipeline,
											},
										  ]
										: !pipeline
											? [
												{
													attribute: 'dealStage',
													multiple: true,
													title: 'Stage',
												},
												{
													attribute: 'pipeline',
													multiple: true,
													title: 'Pipeline',
												},
												{
													attribute: 'status',
													multiple: true,
													title: 'Status',
												},
												// {
												// 	attribute: 'ownerId',
												// 	multiple: true,
												// 	title: 'Assignee',
												// },
										  ]
											: []
								}
							/>
						</Grid>
						{isClaims && (
							<ExportButton columns={headCells} tab={tab}/>
						)}
					</Grid>
				)}
				{isNewBusiness && showProspects && (
					<Grid
						size={{ xs: 12, sm: 6 }}
						container
						justifyContent={'flex-end'}
						display={'flex'}
					>
						<Box pr={1}>
							<ClientTypeRefinementListSelect
								attribute='isProspect'
								title='Client Type'
								prospectFilter={prospectFilter}
								setProspectFilter={setProspectFilter}
							/>
						</Box>
					</Grid>
				)}
			</Grid>
			<Stack
				direction='row'
				sx={{
					...(!features.showKanban && selected.length === 0 && { display: 'hidden' }),
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				{features.showKanban && (
					<Box p={1} px={2}>
						<KanbanToggle />
					</Box>
				)}
				{selected.length > 0 && (
					<Box px={'1em'}>
						<IconButton size='small' onClick={() => dispatch(setSelected([]))}>
							<ClearIcon />
						</IconButton>
						<Typography variant='task_updated'>{`${selected.length} deals selected`}</Typography>
					</Box>
				)}
			</Stack>
			<Divider />
			<Box sx={{ overflowX: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
				{/* {isClaims ? (
					<HitsDataGrid
						isClaims={isClaims}
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						includeTasks={isClaims}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				) : ( */}
				<TableContainer component={Paper}>
					<Table
						aria-labelledby='tableTitle'
						size='medium'
						aria-label='enhanced table'
						sx={{ minWidth: 700 }}
					>
						<SearchFolioHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
							items={sortItems}
						/>
						<HitsTableBody
							TableRowComponent={
								!pipeline ? RenewalsRow : isClaims ? TicketRow : DealRow
							}
							objectType={isClaims ? 'ticket' : 'deal'}
							includeTasks={isClaims}
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							// includePolicies={!pipeline}
							ErrorRowComponent={() => (
								<TableRow>
									<TableCell colSpan={headCells.length} align='center'>
										<Typography variant='subtitle1'>
												There was an error. Please try again.
										</Typography>
									</TableCell>
								</TableRow>
							)}
							EmptyRowComponent={() => (
								<TableRow>
									<TableCell colSpan={headCells.length} align='center'>
										<Typography variant='subtitle1'>
											{`No ${isClaims ? 'claims' : 'deals'} found`}
										</Typography>
									</TableCell>
								</TableRow>
							)}
							isClaims={isClaims}
						/>
					</Table>
				</TableContainer>
				{/* )} */}
			</Box>
			<HitsTablePagination
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				items={rowLimits.map((item) => ({
					label: item,
					value: item,
					default: item == rowsPerPage,
				}))}
			/>
			{/* <DevJSONView value={selected} /> */}
		</InstantSearch>
	);
}
