import React, { useEffect, useState } from 'react';
import {
	Select,
	MenuItem,
	Skeleton,
	OutlinedInput,
	InputAdornment,
	CircularProgress,
	IconButton,
	Chip,
	Box,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { theme } from '@/app/theme';
import { useGetBrokerOwnersQuery } from '@/features/user/ownerApi';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const OwnerSelect = ({ initialId, disabled, onChange, minimal, allowRemoval }) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });
	const hubspotId = userQuery.data?.hubspotId;

	// Set selectedOwner based on initialId, or fall back to hubspotId
	// const [selectedOwner, setSelectedOwner] = useState(initialId ?? hubspotId);

	const ownerQuery = useGetBrokerOwnersQuery(hubspotId, {
		skip: !hubspotId,
	});

	const owners = (ownerQuery.data || []).reduce((acc, current) => {
		if (!acc.find((item) => item.id === current.id)) {
			acc.push(current);
		}
		return acc;
	}, []);

	const handleSelectionChange = (event) => {
		onChange(event.target.value);
	};

	const minimalInputTheme = minimal ? theme.components.MuiInputBase.styleOverrides.minimal : {};

	return ownerQuery.isLoading || ownerQuery.isUninitialized ? (
		<Skeleton height='85%' width='100%' animation='wave' />
	) : (
		<Select
			className={minimal ? 'minimal-input' : ''}
			value={initialId}
			// value={selectedOwner || ''}
			size={minimal ? 'small' : 'medium'}
			disabled={disabled}
			onChange={handleSelectionChange}
			displayEmpty
			fullWidth
			variant={minimal ? 'standard' : 'outlined'}
			{...(allowRemoval && (initialId ?? '').length > 0 && {
				input: (
					<OutlinedInput
						endAdornment={
							<InputAdornment position='end' sx={{ paddingRight: '2em' }}>
								{ownerQuery.isLoading ? (
									<CircularProgress color='inherit' size={20} />
								) : null}
								{(initialId ?? '').length > 0 && (
									<IconButton
										size='small'
										disabled={ownerQuery.isLoading || disabled}
										sx={{ padding: '0' }}
										onClick={(e) => {
											e.stopPropagation();
											onChange('');
										}}
									>
										<ClearIcon fontSize='20px' />
									</IconButton>
								)}
							</InputAdornment>
						}
					/>
				),
			})}
			sx={{
				...(minimal && {
					...minimalInputTheme,
					'& .Mui-disabled': {
						backgroundColor: 'transparent',
					},
				}),
			}}
		>
			{owners.map((option) => (
				<MenuItem value={option.id} key={`owner-selection-${option.id}`}>
					{option.firstName ? `${option.firstName} ${option.lastName}` : option.email}
				</MenuItem>
			))}
		</Select>
	);
};
