/* eslint-disable no-mixed-spaces-and-tabs */
import { createSlice, current } from '@reduxjs/toolkit';

const loadTableOrders = () => {
	const storageValue = localStorage.getItem('columnHeadersOrders');
	const parsedJson = storageValue ? JSON.parse(storageValue) : { orders: {} };
	return parsedJson.orders ?? {};
};

const saveTableOrders = (slug, order, orderBy) => {
	const storedOrders = JSON.parse(localStorage.getItem('columnHeadersOrders')) || { orders: {} };
	if (!storedOrders.orders) {
		storedOrders.orders = {};
	}
	storedOrders.orders[slug] = { order, orderBy };
	localStorage.setItem('columnHeadersOrders', JSON.stringify(storedOrders));
};

export const generateInitialState = () => ({
	endAt: null,
	pageAfter: null,
	rowsPerPage: 30,
	openDialog: false,
	searchValue: '',
	searchText: '',
	orderBy: 'createdate',
	order: 'desc',
	orders: loadTableOrders(),
	page: 0,
	rows: [],
	selected: [],
	refreshIndicator: 0,
});

export const createTableSlice = (sliceName) => {
	return createSlice({
		name: sliceName,
		initialState: generateInitialState(),
		reducers: {
			setPageAfter: (state, action) => {
				state.pageAfter = action.payload;
			},
			setRowsPerPage: (state, action) => {
				state.rowsPerPage = action.payload;
			},
			setOpenDialog: (state, action) => {
				state.openDialog = action.payload;
			},
			setSearchValue: (state, action) => {
				state.searchValue = action.payload;
			},
			setSearchText: (state, action) => {
				state.searchText = action.payload;
			},
			setOrderBy(state, action) {
				const { slug, orderBy } = action.payload;
				if (!state.orders[slug]) {
					state.orders[slug] = {};
				}
				state.orders[slug].orderBy = orderBy;
			},
			setOrder(state, action) {
				const { slug, order } = action.payload;
				if (!state.orders[slug]) {
					state.orders[slug] = {};
				}
				state.orders[slug].order = order;
			},
			requestSort: (state, action) => {
				const { slug, property } = action.payload;
				const currentOrder = state.orders[slug]?.order ?? 'asc';
				const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
				state.orders[slug] = { orderBy: property, order: newOrder };
				saveTableOrders(slug, newOrder, property);
			},
			setPage: (state, action) => {
				state.page = action.payload;
			},
			setRows: (state, action) => {
				state.rows = action.payload;
			},
			resetSearchFields: (state) => {
				state.searchValue = '';
				state.searchText = '';
				state.pageAfter = null;
				state.page = 0;
				state.rows = [];
			},
			resetPages: (state) => {
				state.pageAfter = null;
				state.page = 0;
			},
			changeRowsPerPage: (state, action) => {
				state.rowsPerPage = parseInt(action.payload, 10);
				state.pageAfter = null;
				state.endAt = null;
				state.page = 0;
			},
			changePage: (state, action) => {
				const { newPage, lastPageAfter } = action.payload;
				const newIndex = newPage * state.rowsPerPage;
				if (newPage === 0) {
					state.pageAfter = null;
				} else {
					state.pageAfter = newIndex;
				}
				state.page = newPage;
			},
			resetAllPages: (state) => {
				state.pageAfter = null;
				state.page = 0;
			},
			onOrderChange: (state) => {
				state.rows = [];
				state.pageAfter = null;
				state.page = 0;
			},
			setSelected: (state, action) => {
				const newValue = action.payload;
				if (Array.isArray(newValue)) {
					state.selected = newValue;
				} else {
					const currentValues = current(state.selected);
					if (currentValues.some((d) => d.id == newValue.id)) {
						state.selected = currentValues.filter((f) => f.id != newValue.id);
					} else {
						state.selected = [...currentValues, newValue];
					}
				}
			},
			triggerRefresh: (state) => {
				state.refreshIndicator += 1;
			},
		},
	});
};
