import React, { createContext, useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import useDealData, { initialState } from '@/hooks/useDealData';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';

const DealContext = createContext(initialState);

export const DealContextProvider = ({ children }) => {
	const { id, contextValue, isError } = useDealData('deal');
	const dealId = id;

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const {
		data: loggedInUser,
		isLoading: isLoadingLoggedInUser,
		isUninitialized: isUninitLoggedInUser,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const { data: dealRow, isLoading, isUninitialized } = useGetDealRowQuery(id, { skip: !id });

	const canViewDeal = (loggedInUser?.brokerGroupArray ?? [loggedInUser?.brokerId]).includes(
		dealRow?.brokerGroupId
	);

	console.log('DEAL CONTEXT', contextValue);
	return (
		<DealContext.Provider value={contextValue}>
			{isError ? (
				<Box>
					<Typography textAlign='center'>
						There was an issue loading this deal. Please try again.
					</Typography>
				</Box>
			) : !canViewDeal ? (
				<Box>
					{isLoadingLoggedInUser ||
					isUninitLoggedInUser ||
					isLoading ||
					isUninitialized ? (
							<Box display='flex' justifyContent={'center'} pt='1em'>
								<CircularProgress />
							</Box>
						) : (
							<Typography textAlign='center'>
							There was an issue loading this deal: you do not have the necessary
							permissions to view this deal.
							</Typography>
						)}
				</Box>
			) : (
				children
			)}
			{/* {isLoading ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress />
				</div>
			) : isError ? (
				<Box>
					<Typography textAlign="center">
						There was an issue loading this deal. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)} */}
		</DealContext.Provider>
	);
};

export default DealContext;
