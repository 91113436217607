import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { formatCurrency, formatKey, toCamelCase } from '@/utils/constants';
import { DataGrid } from '@mui/x-data-grid';
import { CLASS_OF_RISK_COLUMNS } from '../validationSchema';

const NoRowsOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'0.75em'} pt='0.5em'>
			<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
				Select one insurer per classes of risk from Results tables.
			</Typography>
		</Box>
	);
};

export const RecommendationTable = ({ rows }) => {
	useEffect(() => console.log('ROWS', rows), [rows]);

	const classOfRiskKeys = rows.map((r) => toCamelCase(r.classOfRisk));
    useEffect(() => console.log('classOfRiskKeys', classOfRiskKeys), [classOfRiskKeys]);
	
    useEffect(() => console.log('Object.entries(CLASS_OF_RISK_COLUMNS)', Object.entries(CLASS_OF_RISK_COLUMNS)), [CLASS_OF_RISK_COLUMNS]);
    const validColumns = Object.entries(CLASS_OF_RISK_COLUMNS)
		.filter(([key]) => classOfRiskKeys.map(r => r.toLowerCase()).includes(key.toLowerCase()))
		.map(([key, columns]) => columns);

        useEffect(() => console.log('validColumns', validColumns), [validColumns]);
	
        const columnVisibilityModel = useMemo(
		() =>
			Object.keys(CLASS_OF_RISK_COLUMNS.contents).reduce(
				(acc, key) => {
					acc[key] = validColumns.some((columns) => columns[key]);
					return acc;
				},
				{ naturalDisaster: false, nhi: false, fsl: false }
			),
		[validColumns]
	);

    useEffect(() => console.log('columnVisibilityModel', columnVisibilityModel), [columnVisibilityModel]);

	const columns = [
		{
			field: 'classOfRisk',
			headerName: 'Class of Risk',
			minWidth: 250,
			flex: 1,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => formatKey(params.row.classOfRisk ?? ''),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			minWidth: 200,
			flex: 1,
			editable: false,
			sortable: false,
			display: 'flex',
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			minWidth: 150,
			flex: 0.5,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, false);
			},
		},
		{
			field: 'excess',
			headerName: 'Excess ($)',
			minWidth: 150,
			flex: 0.3,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.excess ?? 0);
				// value = isNaN(value) ? 0 : value;
				return /[^0-9.]/.test(params.row.excess ?? '')
					? params.row.excess
					: formatCurrency(value, false);
			},
		},
		// {
		// 	field: 'companyPremium',
		// 	headerName: 'Company Premium ($)',
		// 	flex: 0.3,
		// 	minWidth: 150,
		// 	editable: false,
		// 	sortable: false,
		// 	display: 'flex',
		// 	renderCell: (params) => {
		// 		let value = parseInt(params.row.companyPremium ?? 0);
		// 		value = isNaN(value) ? 0 : value;
		// 		return formatCurrency(value, false);
		// 	},
		// },
		{
			field: 'naturalDisaster',
			headerName: 'Natural Disaster Premium ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.naturalDisaster ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, true);
			},
		},
		{
			field: 'nhi',
			headerName: 'Natural Hazards Insurance Levy ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.nhi ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, true);
			},
		},
		{
			field: 'fsl',
			headerName: 'Fire Service Levy ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.fsl ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, true);
			},
		},
		{
			field: 'premium',
			headerName: 'Premium (incl.GST) ($)',
			minWidth: 150,
			flex: 0.3,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.premium ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, true);
			},
			cellClassName: 'pinned-column',
			headerClassName: 'pinned-column',
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
				'--DataGrid-overlayHeight': '3em',
				// '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
				'&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
				// '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
				'& .pinned-column': {
					position: 'sticky',
					right: 0,
					background: '#fff', // Match DataGrid background
					zIndex: 3,
					boxShadow: '-3px 0 5px -2px rgba(0,0,0,0.1)', // Simulates frozen effect
				},
			}}
			columnVisibilityModel={columnVisibilityModel}
			width='100%'
			autoHeight
			getRowHeight={() => 'auto'}
			// getEstimatedRowHeight={() => 200}
			rows={rows}
			getRowId={(row) => `${row.classOfRisk}-${row.insurer}`}
			columns={columns}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
			slots={{
				noRowsOverlay: NoRowsOverlay,
			}}
		/>
	);
};
