import React, { useState, useMemo } from 'react'; // Added useMemo
import {
	AppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	Box,
	Typography,
	Avatar,
	useTheme,
	useMediaQuery,
	ListItemText,
	ListItemButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useMsal } from '@azure/msal-react';
import { toggleOpen } from '@/features/sideNav/sideNavSlice';
import { useMSALHelper } from '@/hooks/useMSALHelper';
import EditProfileDrawer from '@/components/layouts/EditProfileDrawer';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import CustomNavLink from '../SideNavBar/CustomNavLink';
import { paths } from '@/app/routes';
export const AccountDropdownMenu = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { handleLogout } = useMSALHelper();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });

	const brokerDetails = userQuery.data?.broker;

	// Feature flag logic
	const featureFlagQuery = useGetFeatureFlagQuery(
		{ feature: 'profile' },
		{ skip: !userQuery.data } // Wait until user data is loaded
	);
	const showProfile = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const [editProfileOpen, setEditProfileOpen] = useState(false);

	const handleDrawerToggle = () => {
		dispatch(toggleOpen());
	};

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	// 3) This function will open the EditProfileDrawer
	const handleProfile = () => {
		handleMenuClose();
		setEditProfileOpen(true);
	};

	const handleSignOut = () => {
		handleMenuClose();
		handleLogout();
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position='static'
				style={{
					background: 'white',
					boxShadow: 'none',
					borderBottom: '1px solid #D9DCFE',
				}}
			>
				<Toolbar
					variant='dense'
					sx={{ justifyContent: isMobile ? 'space-between' : 'flex-end' }}
				>
					{isMobile && (
						<IconButton
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: 'none' } }}
						>
							<MenuIcon />
						</IconButton>
					)}

					{userQuery.data && (
						<div>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								{brokerDetails?.imageUrl ? (
									<Avatar
										sx={{
											width: '1em',
											height: '1em',
											mr: 2,
											backgroundColor: '#34495E',
										}}
										src={brokerDetails?.imageUrl ?? ''}
									/>
								) : (
									<AccountCircle />
								)}
								<Typography variant='broker_header'>
									{brokerDetails?.name || ' '}
								</Typography>
								<IconButton
									size='small'
									aria-controls='menu-appbar'
									aria-haspopup='true'
									onClick={handleMenuOpen}
								>
									<KeyboardArrowDownIcon />
								</IconButton>
							</Box>

							<Menu
								id='menu-appbar'
								anchorEl={anchorEl}
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								keepMounted
								transformOrigin={{ vertical: 'top', horizontal: 'right' }}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
							>
								<MenuItem>
									<ListItemText
										sx={{
											color: 'gray',
											'& .MuiTypography-root': {
												fontSize: '12px !important',
											},
										}}
									>
										{email}
									</ListItemText>
								</MenuItem>
								{showProfile && (
									<MenuItem component={CustomNavLink} to={paths.profile}>
										Profile
									</MenuItem>
								)}
								<MenuItem onClick={handleSignOut}>Sign out</MenuItem>
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
			{/* <EditProfileDrawer
				title="Edit Profile"
				open={editProfileOpen}
				onClose={() => setEditProfileOpen(false)}
			/> */}
		</Box>
	);
};
