// SideNavBar.jsx

import React, { useMemo, useState } from 'react';
import '@/styles/navbar.scss';
import {
	Box,
	Drawer,
	Toolbar,
	List,
	Typography,
	ListItem,
	IconButton,
	Stack,
	useMediaQuery,
	useTheme,
	Chip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from '@/components/buttons/SignInButton';
import { SignOutButton } from '@/components/buttons/SignOutButton';
import { useDispatch, useSelector } from 'react-redux';
import { toggleOpen, toggleWidth } from '@/features/sideNav/sideNavSlice';
import { SideBarListItem } from './SideBarListItem';


import { ClientIcon } from '@/components/icons/Client';
import { TaskIcon } from '@/components/icons/TaskIcon';
import { NewBusinessIcon } from '@/components/icons/newBusiness';
import { ClaimsIcon } from '@/components/icons/ClaimsIcon';
import { EndorsementIcon } from '@/components/icons/Endorsement';
import { RenewalIcon } from '@/components/icons/Renewal';
import PersonIcon from '@mui/icons-material/Person';

import { IS_DEV } from '@/utils/environment';
import { paths } from '@/app/routes';

import EditProfileDrawer from '@/components/layouts/EditProfileDrawer'; 
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const SideNavBar = () => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { open, width, isCollapsed } = useSelector((state) => state.sideNavSlice);
	const collapsed = isCollapsed && !isMobile;

    const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });

    const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'profile' }, { skip: !userQuery.data });
	const showProfile = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	// Local state to open/close the filters drawer (or "Profile" drawer)
	const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

	const handleDrawerToggle = () => dispatch(toggleOpen());
	const handleDrawerWidthToggle = () => dispatch(toggleWidth());

	const isAuthenticated = useIsAuthenticated();

	const homeList = useMemo(() => {
		const list = [
			{
				text: 'Clients',
				icon: <ClientIcon width="30px" height="30px" bgColor="transparent" />,
				link: paths.home,
			},
			{
				text: 'Tasks',
				icon: <TaskIcon width="30px" height="30px" bgColor="transparent" />,
				link: paths.tasks,
			},
		];
		return isAuthenticated ? list : list.slice(0, -2);
	}, [isAuthenticated]);

	const pipelinesList = useMemo(() => {
		const list = [
			{
				text: 'New Business',
				icon: <NewBusinessIcon width="30px" height="30px" bgColor="transparent" />,
				link: paths.newBusiness,
			},
			{
				text: 'Claims',
				icon: <ClaimsIcon width="30px" height="30px" bgColor="transparent" />,
				link: paths.claims,
			},
			{
				text: 'Endorsements',
				icon: <EndorsementIcon width="30px" height="30px" bgColor="transparent" />,
				link: paths.endorsements,
			},
			{
				text: 'Renewals',
				icon: <RenewalIcon width="30px" height="30px" bgColor="transparent" />,
				link: paths.renewals,
			},
		];
		// Filter out 'Invoices' if not in DEV
		return IS_DEV ? list : list.filter((l) => l.text !== 'Invoices');
	}, []);

	const myAccountList = useMemo(() => {
		if (!isAuthenticated) return [];
		return [
			...(showProfile ? [{
				text: 'Profile',
				icon: <PersonIcon width="30px" height="30px" bgColor="transparent"/>,
				link: paths.profile,
			}] : []),
		];
	}, [isAuthenticated, showProfile]);

	const displayList = (list, title) =>
		list.map((item, index) => (
			<SideBarListItem
				key={`side-bar-list-item-${title}-${index}-${item.text}`}
				item={item}
			/>
		));

	const header = (text) => (
		<Typography
			variant="subtitle2"
			sx={{
				color: 'whitesmoke',
				marginLeft: '35px',
				paddingBottom: '8px',
			}}
		>
			{text}
		</Typography>
	);

	return (
		<>
			<Drawer
				className="side_nav_bar"
				sx={{
					width: width,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: width,
						boxSizing: 'border-box',
						border: 'none',
						backgroundColor: '#1C2536',
						transition: theme.transitions.create(['width'], {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
					},
					'& .MuiListItem-root': {
						padding: collapsed ? 0 : '0 16px',
					},
				}}
				variant={isMobile ? 'temporary' : 'permanent'}
				open={isMobile ? open : true}
				onClose={handleDrawerToggle}
				anchor="left"
			>
				<Toolbar />
				{collapsed ? (
					<IconButton
						variant="outlined"
						onClick={handleDrawerWidthToggle}
						sx={{ mb: '1em' }}
					>
						<MenuIcon sx={{ color: 'whitesmoke' }} />
					</IconButton>
				) : (
					<Box sx={{ ml: '20px' }}>
						<Stack
							direction="row"
							justifyContent={'space-between'}
							alignContent={'start'}
						>
							<Stack direction="row" alignItems={'center'} spacing={1}>
								<Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
                  Folio
								</Typography>
								{IS_DEV && <Chip label="DEV" color="primary" size="small" />}
							</Stack>
							<IconButton
								variant="outlined"
								onClick={handleDrawerWidthToggle}
								sx={{ pt: '0.1em' }}
							>
								<KeyboardArrowLeftIcon sx={{ color: 'whitesmoke' }} />
							</IconButton>
						</Stack>
						<Typography variant="caption" sx={{ color: 'whitesmoke' }}>
              Broker Portal
						</Typography>
					</Box>
				)}

				{/* Home Section */}
				<List disablePadding={collapsed}>{displayList(homeList, 'home')}</List>

				{/* Pipelines Section */}
				{isAuthenticated && (
					<List disablePadding={collapsed}>
						{!collapsed && header('Your Pipelines')}
						{displayList(pipelinesList, 'pipelines')}
					</List>
				)}

				{/* My Account Section */}
				<List disablePadding={collapsed}>
					{!collapsed && header('My Account')}
					{isAuthenticated && displayList(myAccountList, 'my-account')}
					<ListItem
						sx={{ ml: '4px' }}
						disablePadding={collapsed}
						disableGutters={collapsed}
					>
						{isAuthenticated ? <SignOutButton /> : <SignInButton showListItem />}
					</ListItem>
				</List>
			</Drawer>

			{/* The Filter (Profile) Drawer controlled by local state */}
			{/* <EditProfileDrawer
				title="Profile"
				filters={[]}
				pipeline={null}
				open={filterDrawerOpen}
				onClose={() => setFilterDrawerOpen(false)}
			/> */}
		</>
	);
};
