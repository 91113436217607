import React, { useState, useRef, useMemo } from 'react';
import {
	Button,
	ButtonGroup,
	Grow,
	Paper,
	Popper,
	MenuItem,
	MenuList,
	ClickAwayListener,
	CircularProgress,
} from '@mui/material/';
import { Delete, Download, ExpandMore } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { determineContext } from '@/hooks/determineContext';
import { useDispatch } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';
import { formatDate } from '@/utils/constants';

// const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

export const DownloadSOAButton = ({ disabled }) => {
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const { soas } = determineContext();

	const otherSoas = useMemo(() => soas.slice(1).filter((s) => s?.downloadUrl != null), [soas]);
	const isGenerating = useMemo(() => soas.length > 0 && soas[0]?.downloadUrl == null, [soas]);

	const handleClick = (index) => {
		try {
			window.open(soas[index].downloadUrl, '_blank');
		} catch (err) {
			console.log('🙅 ~ Error downloading SOA', err);
			dispatch(
				showToast({
					message: 'Error downloading document. Please try again.',
					error: true,
				})
			);
		}
	};

	const handleMenuItemClick = (event, index) => {
		// do the thing
		// setSelectedIndex(index);
		handleClick(index);
		try {
			window.open(soas[index].downloadUrl, '_blank');
		} catch (err) {
			console.log('🙅 ~ Error downloading SOA', err);
			dispatch(
				showToast({
					message: 'Error downloading document. Please try again.',
					error: true,
				})
			);
		}
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup
				variant='text'
				ref={anchorRef}
				size='small'
				aria-label='Button group with a nested menu'
				disableElevation
				sx={{ 
					minWidth: '22%',
					'& .MuiButtonBase-root': {
						borderRight: 'none'
					}
				}}
                
			>
				<Button
					onClick={() => handleClick(0)}
					disabled={disabled}
					startIcon={
						isGenerating ? (
							<CircularProgress color='inherit' size='0.75em' />
						) : (
							<Download />
						)
					}
				>
					{'Download last generated'}
				</Button>
				{otherSoas.length > 0 && (
					<Button
						size='small'
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label='select document to view'
						aria-haspopup='menu'
						disabled={otherSoas.length === 0}
						onClick={handleToggle}
					>
						<ArrowDropDownIcon />
					</Button>
				)}
			</ButtonGroup>
			<Popper
				sx={{ zIndex: 1 }}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id='split-button-menu' autoFocusItem>
									{soas.map((option, index) => (
										<MenuItem
											key={option?.downloadUrl}
											// disabled={index === 2}
											// selected={index === selectedIndex}
											onClick={(event) => handleMenuItemClick(event, index)}
										>
											{`Document created ${formatDate(
												new Date(option.createdWhen),
												true
											)}`}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);

	// return (
	// 	<Button
	// 		size='small'
	// 		sx={{ minWidth: '22%' }}
	// 		onClick={() => {
	// 			try {
	// 				window.open(soas[0].downloadUrl, '_blank');
	// 			} catch (err) {
	// 				console.log('🙅 ~ Error downloading SOA', err);
	// 				dispatch(
	// 					showToast({
	// 						message: 'Error downloading document. Please try again.',
	// 						error: true,
	// 					})
	// 				);
	// 			}
	// 		}}
	// 		disabled={disabled}
	// 		startIcon={
	// 			isGenerating ? <CircularProgress color='inherit' size='0.75em' /> : <Download />
	// 		}
	// 	>
	// 		{'Download last generated'}
	// 	</Button>
	// );
};
