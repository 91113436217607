import React, { useEffect } from 'react';
import { IconButton, Typography } from '@mui/material';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { formatCurrency } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';
import DataGridExcessSelect from '@/components/table/DataGridExcessSelect';
import useCellModes from '@/hooks/useCellModes';
import {
	BOAT,
	BUSINESS_INTERRUPTION,
	CARRIERS_LIABILITY,
	COMMERCIAL_MOTOR,
	CONTENTS,
	CONTRACTORS_PLANT_AND_MACHINERY,
	CYBER_LIABILITY,
	EMPLOYERS_LIABILITY,
	GENERAL_LIABILITY,
	HOUSE,
	MANAGEMENT_LIABILITY,
	MARINE_CARGO,
	MATERIAL_DAMAGE,
	PRIVATE_VEHICLE,
	PROFESSIONAL_INDEMNITY,
	STATUTORY_LIABILITY,
} from '../../CreateNewBusinessForm/validationSchema';
import { CLASS_OF_RISK_COLUMNS } from '../validationSchema';

export const ResultsTable = ({
	rows,
	updateRows,
	removeRow,
	classOfRisk,
	setRowSelectionModel,
	rowSelectionModel,
	disabled,
}) => {
	const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellModes();

	const shownColumns = CLASS_OF_RISK_COLUMNS[classOfRisk] ?? {};
	const columnVisibilityModel = Object.keys(shownColumns)
		.filter((r) => !shownColumns[r])
		.reduce((acc, r) => {
			acc[r] = shownColumns[r];
			return acc;
		}, {});

	const columns = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			renderHeader: () => <></>,
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<></>
				) : (
					GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)
				),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			flex: 1,
			minWidth: 250,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter name of insurer</Typography>
				) : (
					params.row.insurer
				),
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				if (value > 0) {
					return formatCurrency(value, false);
				}
			},
		},
		{
			field: 'excess',
			headerName: 'Excess ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			singleClickCell: true,
			renderCell: (params) => {
				let value = parseInt(params.row.excess ?? 0);
				// value = isNaN(value) ? 0 : value;
				// let value = parseInt(params.row.excess ?? 0);
				// value = isNaN(value) ? 0 : value;
				// return isNaN(value) && !/^\d+$/.test(params.row.excess ?? '') ? params.row.excess : formatCurrency(value, false);
				// if (params.row.insurer === '' || value === 0) {
				if (params.row.insurer === '' || (params.row.excess ?? '').length === 0) {
					return (
						<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
							{params.row.insurer === '' ? '' : 'Enter excess'}
						</Typography>
					);
				}
				return /[^0-9.]/.test(params.row.excess ?? '')
					? params.row.excess
					: formatCurrency(value, false);
				// return formatCurrency(value, false);
			},
			renderEditCell: (params) => <DataGridExcessSelect {...params} />,
		},
		// {
		// 	field: 'companyPremium',
		// 	headerName: 'Company Premium ($)',
		// 	flex: 0.3,
		// 	minWidth: 150,
		// 	editable: !disabled,
		// 	sortable: false,
		// 	display: 'flex',
		// 	renderCell: (params) => {
		// 		let value = parseInt(params.row.companyPremium ?? 0);
		// 		value = isNaN(value) ? 0 : value;
		// 		if (params.row.insurer === '') {
		// 			return (
		// 				<></>
		// 			);
		// 		}
		// 		return formatCurrency(value, false);
		// 	},
		// 	renderEditCell: (params) => (
		// 		<DataGridNumericFormat {...params} isCurrency={true} decimalScale={2} />
		// 	),
		// },
		{
			field: 'naturalDisaster',
			headerName: 'Natural Disaster Premium ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.naturalDisaster ?? 0);
				value = isNaN(value) ? 0 : value;
				if (params.row.insurer === '') {
					return <></>;
				}
				return formatCurrency(value, true);
			},
			renderEditCell: (params) => (
				<DataGridNumericFormat {...params} isCurrency={true} decimalScale={2} />
			),
		},
		{
			field: 'nhi',
			headerName: 'Natural Hazards Insurance Levy ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.nhi ?? 0);
				value = isNaN(value) ? 0 : value;
				if (params.row.insurer === '') {
					return <></>;
				}
				return formatCurrency(value, true);
			},
			renderEditCell: (params) => (
				<DataGridNumericFormat {...params} isCurrency={true} decimalScale={2} />
			),
		},
		{
			field: 'fsl',
			headerName: 'Fire Service Levy ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.fsl ?? 0);
				value = isNaN(value) ? 0 : value;
				if (params.row.insurer === '') {
					return <></>;
				}
				return formatCurrency(value, true);
			},
			renderEditCell: (params) => (
				<DataGridNumericFormat {...params} isCurrency={true} decimalScale={2} />
			),
		},
		{
			field: 'premium',
			headerName: 'Premium (excl.GST) ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseFloat(params.row.premium ?? 0);
				value = isNaN(value) ? 0 : value;
				if (params.row.insurer === '' || value === 0) {
					return (
						<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
							{params.row.insurer === '' ? '' : 'Enter premium'}
						</Typography>
					);
				}
				return formatCurrency(value, true);
			},
			renderEditCell: (params) => (
				<DataGridNumericFormat {...params} isCurrency={true} decimalScale={2} />
			),
		},
		{
			// ...GRID_CHECKBOX_SELECTION_COL_DEF,
			field: 'delete',
			headerName: 'Delete',
			width: 48,
			sortable: false,
			resizable: false,
			display: 'flex',
			renderHeader: (params) => <></>,
			renderCell: (params) =>
				params.row.insurer === '' || rows.length <= 1 ? (
					<></>
				) : (
					<IconButton
						sx={{ fontSize: '10px', padding: 0 }}
						color='rgba(0,0,0,0)'
						onClick={() => removeRow(params.row.id)}
					>
						<Delete />
					</IconButton>
				),
			cellClassName: 'pinned-column',
			headerClassName: 'pinned-column',
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
				'& .pinned-column': {
					position: 'sticky',
					right: 0,
					background: '#fff', // Match DataGrid background
					zIndex: 3,
					boxShadow: '-3px 0 5px -2px rgba(0,0,0,0.1)', // Simulates frozen effect
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => {
				if (disabled) {
					return false;
				}
				if (params.field === 'insurer') {
					return true;
				}
				return (params.row.insurer ?? '').length > 0;
			}}
			isRowSelectable={(params) => {
				const sumInsured = parseInt((params.row?.sumInsured ?? '').toString());
				const excess = parseInt((params.row?.excess ?? '').toString());
				const premium = parseInt((params.row?.premium ?? '').toString());
				return (
					!isNaN(sumInsured) &&
					sumInsured > 0 &&
					(params.row?.excess ?? '').toString().trim().length > 0 &&
					// !isNaN(excess) &&
					// excess > 0 &&
					!isNaN(premium) &&
					premium > 0 &&
					(params.row?.insurer ?? '').toString().length > 0
				);
			}}
			getRowId={(row) => `${row.insurer}`}
			columnVisibilityModel={columnVisibilityModel}
			checkboxSelection
			onRowSelectionModelChange={setRowSelectionModel}
			rowSelectionModel={rowSelectionModel}
			cellModesModel={cellModesModel}
			onCellModesModelChange={handleCellModesModelChange}
			onCellClick={handleCellClick}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
