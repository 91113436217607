import {React, useMemo} from 'react';
import { Box, Typography } from '@mui/material';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { ProfileView } from '@/components/views/ProfileView';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSelector } from 'react-redux';
// import AdvancedProfileForm from '@/components/forms/AdvancedProfileForm';


export const AdvancedProfilePage = () => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userData = useGetUserDetailsQuery({ email }, { skip: !email });

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'profile' }, { skip: !userData.data });
	const showProfile = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);
	return (
		<AuthenticatedPageLayout>
			{/* <ProfileView/> */}
			{showProfile ? (
				<ProfileView/>
			) : (
				<Typography variant='h4' p={2}>
					{'Profile coming soon '}
				</Typography>
			)}
		</AuthenticatedPageLayout>
	);
};