import { createSlice } from '@reduxjs/toolkit';

const loadFilters = () => {
	const storageValue = localStorage.getItem('instantSearch');
	const parsedJson = storageValue ? JSON.parse(storageValue) : { filters: {} };
	return parsedJson.filters ?? {};
};

// const loadColumnHeaders = () => {
// 	const storageValue = localStorage.getItem('columnHeaders');
// 	const parsedJson = storageValue ? JSON.parse(storageValue) : { columns: {} };
// 	return parsedJson.columns ?? {};
// };

export const instantSearchSlice = createSlice({
	name: 'instantSearch',
	initialState: {
		filters: loadFilters(),
		// columnHeaders: loadColumnHeaders(),
	},
	reducers: {
		setFilters: (state, action) => {
			const { slug, type, values } = action.payload;
			if (values && Array.isArray(values)) {
				const valuesArray = values.filter((i) => i);
				if (!state.filters[slug]) {
					state.filters[slug] = {};
				}
				state.filters[slug][type] = valuesArray;

				localStorage.setItem(
					'instantSearch',
					JSON.stringify({ filters: state.filters })
				);
			}
		},
		// setColumnOrder: (state, action) => {
		// 	const { slug, order, orderBy } = action.payload;
		// 	if (!state.columnHeaders[slug]) {
		// 		state.columnHeaders[slug] = {};
		// 	}
		// 	state.columnHeaders[slug].order = order;
		// 	state.columnHeaders[slug].orderBy = orderBy;

		// 	localStorage.setItem(
		// 		'columnHeaders',
		// 		JSON.stringify({ columns: state.columnHeaders })
		// 	);
		// },
	},
});

export const { setFilters } = instantSearchSlice.actions;
export default instantSearchSlice.reducer;


// const filters = {
	// 	'/renewals': {
	// 		'status': [DEAL_STATUS_TYPES.notStarted] // automatically add filter for status on load
	// 	},
	// 	...parsedJson.filters ?? {},
	// };